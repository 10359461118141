<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
				v-model="dialogEdit"
				max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >
                    Добавить район
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field
                                label="Название"
                                v-model="editedItem.name"
                                @keyup.enter="save"
								autofocus
                                @change="haveEditorChanges = true"
                            >
                            </v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление района</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранный район?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить район"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить район"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
    </v-data-table>
</template>

<script>
export default {
    name: "Regions",
    components: {
    },
    data() {
        return {
            list: [],
            user: 0,
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Название',
                    sortable: false,
                },
                {
                    value: 'count',
                    text: 'Количество объектов',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                slug: '',
                needValue: false,
            },
            dialogRemove: false,
            itemToRemove: -1,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getList(this.pagination.page);
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый район' : 'Редактирование района #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/regions?${new URLSearchParams({
                    pg,
                    on,
                })}`);

                list.forEach((singleNew, index) => {
                    list[index].needValue = list[index].needValue?'Да':'';
                    list[index].count = (list[index].count > 0)?list[index].count:'';
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api(`/admin/regions/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            if (parseInt(item.count) > 0) {
                this.$error('Район используется и не может быть удалён');
                return;
            }
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/regions/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/regions/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            slug: this.editedItem.slug,
                            needValue: (this.editedItem.needValue === undefined)?false:this.editedItem.needValue,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/regions", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            slug: this.editedItem.slug,
                            needValue: this.editedItem.needValue,
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>
